import _ from "lodash";
import { writable, derived } from "svelte/store";
import * as yup from "yup";
import { serializeCompany } from "./Serialization";

export const ownerTypes = { Taxpayer: "taxpayer", Spouse: "spouse" };
export const businessTypes = {
  "S Corporation": "s-corporation",
  "C Corporation": "c-corporation",
  Partnership: "partnership",
  "Sole Proprietorship": "sole-proprietorship"
};
let schema = yup.object().shape({
  name: yup.string().nullable().transform(emptyStringToNull).required("Company Name is required."),
  businessType: yup
    .string()
    .oneOf(_.values(businessTypes))
    .when("owner", {
      is: "spouse",
      then: (schema) =>
        schema.notOneOf(
          ["sole-proprietorship"],
          "Sorry, Tax Planner Pro does not support spousal sole proprietorships yet"
        ), // when owner is spouse, reject sole proprietorship
      otherwise: (schema) => schema.required("Business Type is required.")
    }),
  share: yup
    .number()
    .typeError("Ownership must be a number.")
    .min(0, "Min value 0%.")
    .max(100, "Max value 100%.")
    .nullable()
    .transform(emptyStringToNull)
    .required("Ownership is required."),
  owner: yup.string().oneOf(_.values(ownerTypes)).required("Owner is required."),
  startDate: yup
    .string()
    .matches(/^([01]?\d)\/([0123]?\d)\/\d\d\d\d$/, "The date must be in US format.")
    .required("Business start date is required.")
});

export const company = writable({});

export const companyValidation = derived(company, ($c) => {
  try {
    schema.validateSync($c, { abortEarly: false });
    return new Map();
  } catch (err) {
    return new Map(err.inner.map((err) => [err.path, err.errors[0]]));
  }
});

export const companySerialized = derived(company, ($c) => {
  try {
    schema.validateSync($c, { abortEarly: true });
    return serializeCompany($c);
  } catch {
    return null;
  }
});

export const companyVisitors = writable(new Set([]));

export const visitField = (field) => companyVisitors.update((visitors) => new Set([...visitors, field]));

export const init = () => {
  company.set({ name: "", share: 100 });
  companyVisitors.set(new Set([]));
};

export const showCreateCompanyModal = writable(false);

// helper for yup empty string for number transform function
function emptyStringToNull(value, originalValue) {
  return String(originalValue).trim() === "" ? null : value;
}
